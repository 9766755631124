import { useTheme } from '@emotion/react';
import { PostCard } from '../posts/post-card';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Action, City } from '../../models';

export function Viewed({
  title,
  viewed,
  currentCity,
}: {
  title: string;
  currentCity: City;
  viewed: { results: Action[] };
}) {
  const theme = useTheme();
  if (viewed === undefined || !viewed?.results?.length) {
    return null;
  }
  return (
    <div
      // page__viewed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        marginBottom: '4rem',
        ...theme.mixins.row(),
        [DEVICE_SCREENS_MAX_STRING.md]: {
          marginBottom: '2rem',
        },
      }}
    >
      <div // page__controls
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.col(1),
          minHeight: 0,
        }}
      >
        <div // main-block-controls-v2
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 8px',
          }}
        >
          <div
            // main-block-controls-v2
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 0 8px',
            }}
          >
            <div
              // main-block-controls-v2__title
              css={{
                fontFamily: 'Montserrat',
                fontWeight: '700',
                fontSize: '2.43rem',
                display: 'flex',
                color: theme.colors.black,
                [DEVICE_SCREENS_MAX_STRING.sm]: {
                  fontSize: '1.8rem',
                },
              }}
            >
              {title}
            </div>
          </div>
        </div>
      </div>
      <div
        // scroll-wrapper
        css={{
          width: '100%',
          [DEVICE_SCREENS_MAX_STRING.sm]: {
            width: '100%',
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            paddingTop: 7,
            '&>*': {
              whiteSpace: 'normal',
            },
            '::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
          },
        }}
      >
        <div
          // actions actions_scroll
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            ...theme.mixins.flex(),
            [DEVICE_SCREENS_MAX_STRING.sm]: {
              flexWrap: 'nowrap',
            },
          }}
        >
          {viewed.results.map((item) => (
            <PostCard
              key={item.id}
              item={item}
              currentCity={currentCity}
              href={item.url}
              scroll
            />
          ))}
        </div>
      </div>
    </div>
  );
}
